import "assets/global.scss";
import "assets/font/gtamerica/fontfaces.scss";
import "assets/theme-ssi.scss";
import "../aws-amplify.config";

import { ApiProvider } from "libs/lms/src/lib/hooks/api";
import { sanityClient } from "libs/sanity-library/src/lib/sanity.server";
import type { AppProps } from "next/app";
import { groq } from "next-sanity";
import React from "react";

import projectJSON from "../project.json";

const settingsQuery = groq`
  *[_type in ["siteSettings"]] {
    header,
    footer {
      footerImage {
        alt,
        "url": asset->url,
        "width": asset->metadata.dimensions.width,
        "height": asset->metadata.dimensions.height
      },
      footerText,
      footerLinks
    },
    defaultVariables
  }
`;

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;
const projectName = projectJSON.name;
const projectVersion = projectJSON.version;

const CustomApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ApiProvider
      config={{
        apiBaseUrl,
        clientId,
        projectName,
        projectVersion
      }}
    >
      <Component {...pageProps} />
    </ApiProvider>
  );
};

export default CustomApp;
