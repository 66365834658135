//import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import { decode } from "jsonwebtoken";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useReducer, useState } from "react";

//import packageJson from "../package.json";
import authReducer, { STATUSES } from "./authReducer";

export const ROLES = {
  seller: "seller",
  customer: "customer"
};

const ApiContext = React.createContext(null);

const STORAGE_KEYS = {
  token: "auth:token",
  refreshToken: "auth:refreshToken"
};

let http;

function ApiProvider({ config, children }) {
  const [state, dispatch] = useReducer(authReducer, {
    config,
    status: "pending",
    userId: null
  });
  const [sessionId, setSessionId] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [sessionPin, setSessionPin] = useState(null);
  const [scopes, setScopes] = useState([]);

  const apiBaseUrl = config.apiBaseUrl;
  const clientId = config.clientId;

  if (!http) {
    http = axios.create({
      baseURL: apiBaseUrl,
      timeout: 50000,
      headers: {
        "X-Client-Name": config.name, //packageJson.name,
        "X-Client-Version": config.version //packageJson.version
      }
    });

    http.interceptors.request.use((request: any) => {
      const token = sessionStorage.getItem(STORAGE_KEYS.token);

      if (token) {
        request.headers["Authorization"] = `jwt ${token}`;
      }

      return request;
    });

    http.interceptors.response.use(undefined, async (error: any) => {
      const response = error.response;
      if (
        response &&
        response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        if (!sessionStorage.getItem(STORAGE_KEYS.refreshToken)) {
          error.config.__isRetryRequest = true;
          return Promise.reject(error);
        }
        try {
          const result = await fetch(apiBaseUrl + "/auth/jwt", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-type": "application/json"
            },
            redirect: "follow",
            body: JSON.stringify({
              refreshToken: sessionStorage.getItem(STORAGE_KEYS.refreshToken)
            })
          });
          if (result.status === 200 || result.status === 201) {
            const { token, refreshToken } = await result.json();
            setTokens({ token, refreshToken });
          } else {
            await clearTokens();
          }
        } catch (authError) {
          error.config.__isRetryRequest = true;
          await clearTokens();
          return Promise.reject(authError);
        }

        // retry the original request
        error.config.__isRetryRequest = true;
        return http(error.config);
      }
      return Promise.reject(error);
    });
  }

  const setTokens = ({ token, refreshToken }) => {
    setScopes(decode(token).scopes);
    sessionStorage.setItem(STORAGE_KEYS.token, token);
    sessionStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
  };

  const verifyTokens = async () => {
    const token = sessionStorage.getItem(STORAGE_KEYS.token);
    const refreshToken = sessionStorage.getItem(STORAGE_KEYS.refreshToken);
    if (!token || !refreshToken) {
      dispatch({ type: "rejected", action: {} });
      return;
    }

    dispatch({ type: "pending", action: {} });
    setScopes(decode(token).scopes);
    try {
      const response = await http(apiBaseUrl + `/auth/me`);
      if (response.data && !response.data.data.profile_completed) {
        dispatch({
          type: "profile-not-completed",
          action: { userId: response.data.data.id }
        });
        return;
      } else if (response.data && response.data.data.id) {
        dispatch({
          type: "verified",
          action: { userId: response.data.data.id }
        });
        return;
      }
    } catch (error) {
      console.error({ error });
    }
    dispatch({ type: "rejected" });
  };

  const clearTokens = async () => {
    sessionStorage.removeItem(STORAGE_KEYS.token);
    sessionStorage.removeItem(STORAGE_KEYS.refreshToken);
  };

  useEffect(() => {
    verifyTokens();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        state,
        isPaused,
        setIsPaused,
        setSessionPin,
        sessionPin,
        setSessionData,
        setSessionId,
        sessionId,
        sessionData,
        clearTokens,
        setTokens,
        scopes,
        dispatch,
        apiBaseUrl,
        clientId
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

const useAuth = () => {
  const router = useRouter();

  const { apiBaseUrl, setTokens, clearTokens, state, dispatch, clientId } =
    useContext(ApiContext);

  const isPending = state.tokenStatus === STATUSES.PENDING;
  const isError = state.tokenStatus === STATUSES.ERROR;
  const isSuccess = state.tokenStatus === STATUSES.SUCCESS;
  const isVerified = state.tokenStatus === STATUSES.VERIFIED;
  const isAuthenticated = isVerified;
  const adminGroupId = state.adminGroupId;

  const signUp = async ({ groupId, password, email }) => {
    if (typeof window !== "undefined") {
      try {
        const hostname = window.location.origin;
        const url = `${hostname}/api/signUp`;
        let response: any;
        if (groupId) {
          response = await http({
            url,
            method: "POST",
            data: {
              groupId,
              password,
              email
            }
          });
        } else {
          response = await http({
            url,
            method: "POST",
            data: {
              password,
              email
            }
          });
        }

        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    }
  };

  const login = async ({ username, password }) => {
    const result = await fetch(apiBaseUrl + `/auth/otp`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({ username, password })
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: !!res.success };
  };

  const logout = async () => {
    await fetch(`${apiBaseUrl}/auth/logout`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({
        refreshToken: sessionStorage.getItem(STORAGE_KEYS.refreshToken)
      })
    });
    //clearTokens();
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: "logout" });
  };

  const tokensRedirect = (path: string) => {
    const token = sessionStorage.getItem(STORAGE_KEYS.token);
    const refreshToken = sessionStorage.getItem(STORAGE_KEYS.refreshToken);

    if (token === null || refreshToken === null) {
      router.push(path);
    }
  };

  const authenticate = async ({ crossToken }) => {
    try {
      const result = await fetch(
        apiBaseUrl + "/auth/crosstoken/" + crossToken,
        {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-type": "application/json"
          },
          redirect: "follow"
        }
      );

      const { token, refreshToken } = await result.json();

      if (token && refreshToken) {
        dispatch({
          type: "crossToken-confirmed",
          action: { token, refreshToken }
        });
        setTokens({ refreshToken, token });

        // Check /me for userId
        const me = await fetch(apiBaseUrl + `/auth/me`, {
          headers: {
            Authorization: "jwt " + token
          }
        });
        const res = await me.json();

        if (res.data && res.data.id) {
          dispatch({
            type: "verified",
            action: { userId: res.data.id }
          });
          return { success: true };
        }
      }
      dispatch({ type: "rejected" });
      return { success: false };
    } catch (error) {
      console.log({ error });
    }
  };

  const otp = async ({ username, otp }) => {
    try {
      const result = await fetch(apiBaseUrl + `/auth/jwt`, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-type": "application/json"
        },
        redirect: "follow",
        body: JSON.stringify({ username, otp })
      });

      const { token, refreshToken } = await result.json();

      if (token && refreshToken) {
        dispatch({ type: "otp-confirmed", action: { token, refreshToken } });
        setTokens({ refreshToken, token });

        // Check /me for userId
        const me = await fetch(apiBaseUrl + `/auth/me`, {
          headers: {
            Authorization: "jwt " + token
          }
        });
        const res = await me.json();
        if (res.data && res.data.id) {
          dispatch({
            type: "verified",
            action: { userId: res.data.id }
          });
          return { success: true };
        }
      }
      dispatch({ type: "rejected" });
      return { success: false };
    } catch (error) {
      console.log({ error });
    }
  };

  const forgotPassword = async ({ username }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({ username })
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: !!res.success };
  };

  const resetPassword = async ({ username, password, otp }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({ username, password, otp })
    });

    if (!result.ok) {
      return { success: false };
    }
    const res = await result.json();

    return { success: !!res.success };
  };

  const loginjwt = async (loginToken) => {
    const result = await fetch(apiBaseUrl + `/auth/jwks/idToken`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({ idToken: loginToken, clientId })
    });

    const { token, refreshToken } = await result.json();
    if (token && refreshToken) {
      dispatch({ type: "otp-confirmed", action: { token, refreshToken } });
      setTokens({ refreshToken, token });

      // Check /me for userId
      const me = await fetch(apiBaseUrl + `/auth/me`, {
        headers: {
          Authorization: "jwt " + token
        }
      });
      const res = await me.json();
      if (res.data && res.data.id) {
        dispatch({
          type: "verified",
          action: { userId: res.data.id }
        });
        return { success: true };
      }
      if (res.data && res.data.id) {
        if (res.data.profile_completed) {
          dispatch({ type: "verified", action: { userId: res.data.id } });
          return { success: true };
        }
        dispatch({
          type: "profile-not-completed",
          action: { userId: res.data.id }
        });
        return { success: false };
      }
    }
    dispatch({ type: "rejected" });
    return { success: false };
  };

  return {
    ...state,
    isPending,
    isError,
    signUp,
    login,
    otp,
    logout,
    isSuccess,
    forgotPassword,
    resetPassword,
    authenticate,
    isAuthenticated,
    adminGroupId,
    loginjwt,
    tokensRedirect
  };
};

const useCourses = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const [courses, setCourses] = useState(null);

  const getCourses = async () => {
    const response = await http({
      url: `${apiBaseUrl}/courses`,
      method: "GET"
    });
    return response.data;
  };

  useEffect(() => {
    const f = async () => {
      const c = await getCourses();
      setCourses(c);
    };
    f();
  }, []);

  const getCourse = async (courseId) => {
    const response = await http({
      url: `${apiBaseUrl}/courses/${courseId}`,
      method: "GET"
    });
    return response.data;
  };

  return {
    courses,
    getCourse
  };
};

const useEmailSending = () => {
  const { apiBaseUrl } = useContext(ApiContext);

  const sendEmailWithType = async (
    userId: string,
    type: string,
    replaceNameInMailUserId?: string
  ) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/emailsending/${userId}/type/${type}`,
        method: "POST",
        data: {
          userId: replaceNameInMailUserId ?? null
        }
      });
      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  const sendWelcomeMail = async (id: string) => {
    if (typeof window !== "undefined") {
      const hostname = window.location.origin;
      const url = `${hostname}/api/sendWelcomeEmail`;
      try {
        const response = await http({
          url,
          method: "POST",
          data: { id }
        });
        return response.data;
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const scheduleEmail = async (
    id: string,
    secondsInFuture: number,
    emailType: string,
    courseId?: string
  ) => {
    try {
      let data: any = {};
      data = {
        secondsInFuture,
        emailType
      };
      if (courseId) {
        data = {
          ...data,
          courseId
        };
      }
      const response = await http({
        url: `${apiBaseUrl}/emailsending/${id}/schedule`,
        method: "POST",
        data: {
          data: {
            type: "emailschedule",
            attributes: data
          }
        }
      });
      //console.log({ response });

      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  const getScheduledEmails = async (userId) => {
    const response = await http({
      url: `${apiBaseUrl}/emailsending/${userId}`,
      method: "GET"
    });

    return response.data;
  };

  const updateScheduledEmail = async ({ id, secondsInFuture }) => {
    const response = await http({
      url: `${apiBaseUrl}/emailsending/${id}/schedule`,
      method: "PATCH",
      data: {
        data: {
          type: "emailschedule",
          attributes: {
            secondsInFuture
          }
        }
      }
    });
    return response;
  };

  const deleteScheduledEmail = async ({ userId, emailType }) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/emailsending/${userId}/schedule`,
        method: "DELETE",
        data: {
          data: {
            type: "emailschedule",
            attributes: {
              emailType
            }
          }
        }
      });
      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    sendEmailWithType,
    sendWelcomeMail,
    scheduleEmail,
    getScheduledEmails,
    updateScheduledEmail,
    deleteScheduledEmail
  };
};

const useGroups = () => {
  const { apiBaseUrl } = useContext(ApiContext);

  const createGroup = async ({ data }) => {
    if (typeof window !== "undefined") {
      const hostname = window.location.origin;
      const url = `${hostname}/api/createGroup`;
      const response = await http({
        url,
        method: "POST",
        data: {
          data: {
            type: "groups",
            attributes: { ...data }
          }
        }
      });
      return response.data;
    }
  };

  const getGroupsWithClientId = async () => {
    const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;
    const response = await http({
      url: `${apiBaseUrl}/groups/client/${clientId}`,
      method: "GET"
    });
    return response.data;
  };

  const updateGroup = async ({ id, data }) => {
    if (typeof window !== "undefined") {
      const hostname = window.location.origin;
      const url = `${hostname}/api/updateGroup`;
      try {
        const response = await http({
          url,
          method: "PATCH",
          data: {
            data: {
              type: "groups",
              id,
              attributes: { ...data }
            }
          }
        });

        return response.data;
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const checkGroupByOrgNr = async (orgNr) => {
    const response = await http({
      url: `${apiBaseUrl}/groups/orgNr/${orgNr}`,
      method: "GET"
    });
    return response.data;
  };

  const findMatchingRegion = async (
    name: string,
    regionNumber: string,
    clientId: string
  ) => {
    const response = await http({
      url: `${apiBaseUrl}/groups/region`,
      method: "POST",
      data: { name, regionNumber, clientId }
    });
    return response.data;
  };

  return {
    createGroup,
    getGroupsWithClientId,
    updateGroup,
    checkGroupByOrgNr,
    findMatchingRegion
  };
};

const useLMS = () => {
  const { apiBaseUrl } = useContext(ApiContext);

  const createAttempt = async (userId, courseId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/`,
        method: "POST",
        data: {
          data: {
            userId,
            courseId
          }
        }
      });
      //console.log({ response });
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;
      if (response.data.data.attributes.status === "NOT ATTEMPTED")
        response.data.data.attributes.status = "INCOMPLETE";
      return response.data;
    } catch (error) {
      console.log({ error });
    }
  };

  const getAttempt = async (attemptId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "GET"
      });
      // removing data we are not supposed to change clientside
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;
      if (response.data.data.attributes.status === "NOT ATTEMPTED")
        response.data.data.attributes.status = "INCOMPLETE";
      return response.data;
    } catch (error) {
      console.log({ error });
    }
  };

  const updateAttempt = async (attemptId, attributes) => {
    //console.log({ attemptId });
    if (attemptId == undefined || attributes == undefined) return;
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "PATCH",
        data: {
          data: {
            id: attemptId,
            type: "attempts",
            attributes: attributes
          }
        }
      });
      return response.data;
    } catch (error) {
      console.log({ error });
    }
  };

  const getLearningpaths = async () => {
    const response = await http({
      url: `${apiBaseUrl}/learningpaths`,
      method: "GET"
    });

    return response.data;
    // { attributes, relationships, links included }
  };

  const getDiplomasSent = async ({ userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/user/${userId}`,
      method: "GET"
    });
    return response.data;
  };

  const forwardDiploma = async ({
    learningpathId,
    userId,
    to,
    finished = false
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}/forward`,
      method: "POST",
      data: {
        query: {
          to,
          finished,
          locale: "nb-NO"
        }
      }
    });
    return response;
  };

  const forwardDiplomaWithRepetition = async ({
    userId,
    learningpathId,
    repLearningpathId,
    to,
    finished = false
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}/secondarylearningpath/${repLearningpathId}/forward`,
      method: "POST",
      data: {
        query: {
          to,
          finished
        }
      }
    });
    return response;
  };

  const downloadDiploma = async ({ userId, learningpathId }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      responseType: "blob"
    });
    return response;
  };

  const downloadDiplomaWithRepetition = async ({
    userId,
    learningpathId,
    repLearningpathId
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}/secondarylearningpath/${repLearningpathId}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      responseType: "blob"
    });
    return response;
  };

  /*
  example of usage:
          const meta = {
          feedback: [
            {
              id: 1,
              answer: 5
            },
            {
              id: 2,
              answer: 3
            },
            {
              id: 3,
              answer: "jadda her er det noe tekst vettu"
            }
          ]
        };

        const res = await updateCourseUserStatus({
          attemptId: "4a172d08-0254-11ee-83cf-0242ac120002",
          userId: "e94f1fc4-f968-11ed-8a5b-0242ac120002",
          courseId: "39fafa0a-b8dd-11ed-b32d-f6abcc910869",
          metaData: meta
        });
        console.log({ res });
        if(res.status !== 200) {
          console.log("Update did not work")
        }
  */
  const updateCourseUserStatus = async ({
    attemptId,
    userId,
    courseId,
    metaData
  }) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}/courseuser`,
        method: "PATCH",
        data: {
          data: {
            type: "courseUser",
            id: attemptId,
            attributes: {
              userId,
              courseId,
              meta: metaData
            }
          }
        }
      });
      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  const getCourseUserStatus = async (userId, courseId) => {
    const response = await http({
      url: `${apiBaseUrl}/attempts/${userId}/courseuser/${courseId}`,
      method: "GET"
    });
    return response.data;
  };

  const updateLearningpathUserStatus = async ({
    userId,
    learningpathId,
    completedAt
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/learningpathuserstatuses/user/${userId}/learningpath/${learningpathId}`,
      method: "PATCH",
      data: {
        completedAt
      }
    });
    return response;
  };

  return {
    createAttempt,
    getAttempt,
    updateAttempt,
    getLearningpaths,
    updateCourseUserStatus,
    getCourseUserStatus,
    updateLearningpathUserStatus,
    getDiplomasSent,
    forwardDiploma,
    forwardDiplomaWithRepetition,
    downloadDiploma,
    downloadDiplomaWithRepetition
  };
};

const useStatistics = () => {
  const { apiBaseUrl } = useContext(ApiContext);

  const getMenteesData = async (id: string) => {
    const response = await http({
      url: `${apiBaseUrl}/statistics/mentor-users/${id}`,
      method: "GET"
    });
    return response.data;
  };

  return {
    getMenteesData
  };
};

const useUsers = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const { isAuthenticated, userId } = useAuth();
  const [user, setUser] = useState(null);

  const getUser = async (userId: string) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "GET"
    });
    return response.data;
  };

  useEffect(() => {
    const f = async () => {
      const response = await getUser(userId);
      const { firstName, lastName, email, meta, language } =
        response?.data?.attributes ?? null;

      const uc = await getUserCourses(userId);
      const ulp = await getUserLearningpaths(userId);
      const ug = await getUserGroups(userId);

      const mainPath = ulp.find((main: any) => main.meta.type === "path");

      // detect userlevel
      const userRole = ug.included.find(
        (group) => group.attributes.meta.role === "user-role"
      );
      const federation = ug.included.find(
        (group) => group.attributes.meta.role === "federation"
      );
      const federationChild = ug.included.find(
        (group) => group.attributes.meta.role === "federation-child"
      );
      setUser({
        id: userId,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        meta,
        language,
        userRole:
          userRole?.attributes?.meta?.type ??
          userRole?.attributes?.meta?.shortname,
        styrerId: federation?.attributes?.meta?.userId ?? null,
        federation,
        federationChild,
        userCourses: uc,
        userLearningpath: mainPath
      });
    };
    if (isAuthenticated && userId) f();
  }, [isAuthenticated, userId]);

  const createAdminUser = async ({ data }) => {
    if (typeof window !== "undefined") {
      const hostname = window.location.origin;
      const url = `${hostname}/api/createAdminUser`;
      const response = await http({
        url,
        method: "POST",
        data: {
          data: {
            type: "users",
            attributes: { ...data }
          }
        }
      });
      return response.data;
    }
  };

  const getUserGroups = async (userId: string) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "GET"
    });
    return response.data;
  };

  const getUserCourses = async (userId: string) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/courses`,
      method: "GET"
    });
    return response.data;
  };

  const getUserLearningpaths = async (userId: string) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/learningpaths`,
      method: "GET"
    });
    return response.data.data;
  };

  const getUserLearningpathWithStatuses = async ({
    userId,
    learningpathId
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/learningpaths/${learningpathId}`,
      method: "GET"
    });
    return response.data.data[0];
  };

  const updateUser = async ({
    userId,
    data
  }: {
    userId: string;
    data: any;
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "PATCH",
      data: {
        data: {
          type: "users",
          id: userId,
          attributes: { ...data }
        }
      }
    });
    return response;
  };

  const updateEmail = async ({
    userId,
    data
  }: {
    userId: string;
    data: any;
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}?skipCognitoUpdate=true`,
      method: "PATCH",
      data: {
        data: {
          type: "users",
          id: userId,
          attributes: { ...data }
        }
      }
    });
    return response;
  };

  const deleteUser = async ({ userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}?hard=true`,
      method: "DELETE"
    });
    return response.data;
  };

  const addUserToGroups = async ({ groupIds, userId }) => {
    const requestData = [];
    for (const groupId of groupIds) {
      requestData.push({ type: "groups", id: groupId });
    }
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "POST",
      data: { data: requestData }
    });
    return response.data;
  };

  const removeUserFromGroups = async ({ groupIds, userId }) => {
    const requestData = [];
    for (const groupId of groupIds) {
      requestData.push({ type: "groups", id: groupId });
    }
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "DELETE",
      data: { data: requestData }
    });
    return response.data;
  };

  return {
    userId,
    user,
    getUser,
    createAdminUser,
    getUserGroups,
    getUserCourses,
    getUserLearningpaths,
    getUserLearningpathWithStatuses,
    updateUser,
    updateEmail,
    deleteUser,
    addUserToGroups,
    removeUserFromGroups
  };
};

export {
  ApiProvider,
  useAuth,
  useCourses,
  useEmailSending,
  useGroups,
  useLMS,
  useStatistics,
  useUsers
};
