/**
 * Token statuses.
 * TODO: REJECTED and RESOLVED was added as ERROR="rejected" and SUCCESS="resolved" (no actual match). Consider removing ERROR and SUCCESS, or map them to their actual meaning?
 */
export const STATUSES = {
  PENDING: "pending",
  ERROR: "rejected",
  SUCCESS: "resolved",
  VERIFIED: "verified",
  NOTAPPLICABLE: "na",
  REJECTED: "rejected",
  RESOLVED: "resolved"
};

function authReducer(state, action) {
  switch (action.type) {
    case "logout": {
      return {
        ...state,
        tokenStatus: STATUSES.NOTAPPLICABLE
      };
    }
    case "rejected": {
      return {
        ...state,
        tokenStatus: STATUSES.REJECTED,
        error: action.error
      };
    }
    case "missing-tokens": {
      return {
        ...state,
        tokenStatus: STATUSES.NOTAPPLICABLE
      };
    }
    case "pending": {
      return {
        ...state,
        tokenStatus: STATUSES.PENDING
      };
    }
    case "resolved": {
      return {
        ...state,
        tokenStatus: STATUSES.RESOLVED
      };
    }
    case "otp-confirmed": {
      return {
        ...state,
        tokenStatus: STATUSES.RESOLVED,
        token: action.action.token,
        refreshToken: action.action.refreshToken
      };
    }
    case "crossToken-confirmed": {
      if (state.tokenStatus === STATUSES.VERIFIED) {
        return {
          ...state
        };
      }
      return {
        ...state,
        tokenStatus: STATUSES.RESOLVED,
        token: action.action.token,
        refreshToken: action.action.refreshToken
      };
    }
    case "verified": {
      return {
        ...state,
        tokenStatus: STATUSES.VERIFIED,
        userId: action.action.userId
        //adminLevel: action.action.scopeLevel.level,
        //adminGroupId: action.action.scopeLevel.id
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default authReducer;
